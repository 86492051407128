import "./Header.css"
import sign from './sign.png';
import photoID from './photoID.jpg';
import React, { useState, useEffect } from 'react';

const Header = () => {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 970px)").matches
      )
    
    useEffect(() => {
        window
        .matchMedia("(min-width: 970px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <>
        {matches && (
            <>
            <div className="myHeader">
                <img className="logoImage" src={sign} alt="logo"></img>
                <div className="bioContainer">
                    <p>Franck Carvin, artiste peintre passionné, révèle son talent depuis son jeune âge. Après une formation d'illustrateur, il affine sa maîtrise des détails et des émotions.</p>
                    <p>Aujourd'hui, il crée des œuvres profondes en utilisant des techniques variées comme le fusain et l'acrylique.</p>
                    <p>Inspiré par le romantisme, ses toiles aux textures et contrastes saisissants invitent à une expérience visuelle poétique et introspective, à la recherche de beauté et de vérité.</p>
                    <h1>Franck Carvin</h1>
                </div>
                <img className="photoID" src={photoID} alt="logo"></img>
            </div>
            <hr/>
            </>
        )}
        {!matches && (
            <>
            <div className="myHeader">
                <img className="logoImage" src={sign} alt="logo"></img>
                
                <img className="photoID" src={photoID} alt="logo"></img>

                
            </div>
            <div className="bioContainer">
                <p>Franck Carvin, artiste peintre passionné, révèle son talent depuis son jeune âge. Après une formation d'illustrateur, il affine sa maîtrise des détails et des émotions.</p>
                <p>Aujourd'hui, il crée des œuvres profondes en utilisant des techniques variées comme le fusain et l'acrylique.</p>
                <p>Inspiré par le romantisme, ses toiles aux textures et contrastes saisissants invitent à une expérience visuelle poétique et introspective, à la recherche de beauté et de vérité.</p>
                <h1>Franck Carvin</h1>
            </div>
        <hr/>
        </>
        )}
        </>
    );
}

export default Header