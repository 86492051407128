export const photos = [
    {
      src: "./tableaux/19 L_Aigle noir 90x90 cm.jpg",
      alt:"L_Aigle noir 90x90 Franck Carvin",
      width: 9,
      height: 9,
      title: "L_Aigle noir 90x90",
      caption: "L_Aigle noir 90x90",
      loading: 'lazy'
    },
    {
      src: "./tableaux/18 Fierté silencieuse 93x73 cm.jpg",
      alt:"Fierté silencieuse 93x73 Franck Carvin",
      width: 7.3,
      height: 9.3,
      title: "Fierté silencieuse 93x73",
      caption: "Fierté silencieuse 93x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/17 Ange déchu 146x89 cm.jpg",
      alt:"Ange déchu 146x89 Franck Carvin",
      width: 8.9,
      height: 14.6,
      title: "Ange déchu 146x89",
      caption: "Ange déchu 146x89",
      loading: 'lazy'
    },
    {
      src: "./tableaux/16 Gardienne de la pensée 116x81 cm.jpg",
      alt:"Gardienne de la pensée 116x81 Franck Carvin",
      width: 8.1,
      height: 11.6,
      title: "Gardienne de la pensée 116x81",
      caption: "Gardienne de la pensée 116x81",
      loading: 'lazy'
    },
    {
      src: "./tableaux/15 Angel 116x89 cm.jpg",
      alt:"Angel 89x116 Franck Carvin",
      width: 11.6,
      height: 8.9,
      title: "Angel 89x116",
      caption: "Angel 89x116",
      loading: 'lazy'
    },
    {
      src: "./tableaux/14 Transcendance 130x89 cm.jpg",
      alt:"Transcendance 89x130 Franck Carvin",
      width: 13,
      height: 8.9,
      title: "Transcendance 89x130",
      caption: "Transcendance 89x130",
      loading: 'lazy'
    },
    {
      src: "./tableaux/13 Wolf 130x97 cm.jpg",
      alt:"Wolf 130x97 Franck Carvin",
      width: 9.7,
      height: 13,
      title: "Wolf 130x97",
      caption: "Wolf 130x97",
      loading: 'lazy'
    },
    {
      src: "./tableaux/12 L_Arcane des séraphins 130x81 cm.jpg",
      alt:"L_Arcane des séraphins 130x81 Franck Carvin",
      width: 8.1,
      height: 13,
      title: "L_Arcane des séraphins 130x81",
      caption: "L_Arcane des séraphins 130x81",
      loading: 'lazy'
    },
    {
      src: "./tableaux/11 Souverain des Ethers 130x97 cm.jpg",
      alt:"Souverain des Ethers 130x97 Franck Carvin",
      width: 9.7,
      height: 13,
      title: "Souverain des Ethers 130x97",
      caption: "Souverain des Ethers 130x97",
      loading: 'lazy'
    },
    {
      src: "./tableaux/10 Depardieu 92x73 cm.jpg",
      alt:"Depardieu 92x73 Franck Carvin",
      width: 7.3,
      height: 9.2,
      title: "Depardieu 92x73",
      caption: "Depardieu 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/09 Féroce 120x120 cm.jpg",
      alt:"Féroce 120x120 Franck Carvin",
      width: 12,
      height: 12,
      title: "Féroce 120x120",
      caption: "Féroce 120x120",
      loading: 'lazy'
    },
    {
      src: "./tableaux/08 Soleil levant 73x65 cm.jpg",
      alt:"Soleil levant 73x65 Franck Carvin",
      width: 6.5,
      height: 7.3,
      title: "Soleil levant 73x65",
      caption: "Soleil levant 73x65",
      loading: 'lazy'
    },
    {
      src: "./tableaux/07 La fille au ballon 92x73 cm.jpg",
      alt:"La fille au ballon 92x73 Franck Carvin",
      width: 7.3,
      height: 9.2,
      title: "La fille au ballon 92x73",
      caption: "La fille au ballon 92x73",
      loading: 'lazy'
    },
    {
      src: "./tableaux/06 Kong 100x81 cm.jpg",
      alt:"Kong 100x81 Franck Carvin",
      width: 8.1,
      height: 10,
      title: "Kong 100x81",
      caption: "Kong 100x81",
      loading: 'lazy'
    },
    {
      src: "./tableaux/05 Renaud 65x54 cm.jpg",
      alt:"Renaud 65x54 Franck Carvin",
      width: 5.4,
      height: 6.5,
      title: "Renaud 65x54",
      caption: "Renaud 65x54",
      loading: 'lazy'
    },
    {
      src: "./tableaux/04 Bashung 73x60 cm.jpg",
      alt:"Bashung 73x60 Franck Carvin",
      width: 6,
      height: 7.3,
      title: "Bashung 73x60",
      caption: "Bashung 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/03 Cabrel 73x60 cm.jpg",
      alt:"Cabrel 73x60 Franck Carvin",
      width: 6,
      height: 7.3,
      title: "Cabrel 73x60",
      caption: "Cabrel 73x60",
      loading: 'lazy'
    },
    {
      src: "./tableaux/02 Laura 65x54 cm.jpg",
      alt:"02 Laura 65x54 Franck Carvin",
    width: 5.4,
    height: 6.5,
    title: "Laura 65x54",
    caption: "Laura 65x54",
    loading: 'lazy'
    },
    {
      src: "./tableaux/01 Séréna 65x54 cm.jpg",
      alt:"01 Séréna 65x54 Franck Carvin",
    width: 5.4,
    height: 6.5,
    title: "Séréna 65x54",
    caption: "Séréna 65x54",
    loading: 'lazy'
    },
  ];
  