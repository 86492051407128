import "./home.css"
import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import { photos } from "../../photos.js"
import Lightbox from 'react-spring-lightbox';
import ArrowButton from "../../components/ArrowButton";
// import ImageOverlay from "../../components/ImageOverlay";
import styled from "styled-components";
import Color from "color";
import GalleryFooter from "../../components/GalleryFooter/GalleryFooter";
import Footer from "../../components/Footer/Footer";

import {Helmet} from 'react-helmet'

const Home = () => {
    const [currentImage, setCurrentIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const [currentImageTitle, setCurrentIndexTitle] = useState(0);
    
    useEffect(() => {
        setCurrentIndexTitle((photos[currentImage]).caption)
        console.log(currentImageTitle);
    },[currentImage]);

    const openLightbox = useCallback((event, { index }) => {
        setCurrentIndex(index);
        setOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentIndex(0);
        setOpen(false);
    };

    const gotoPrevious = () =>
    currentImage > 0 && setCurrentIndex(currentImage - 1);

    const gotoNext = () => {
    currentImage + 1 < photos.length &&
        setCurrentIndex(currentImage + 1);
    }

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 970px)").matches
      )
    
    useEffect(() => {
        window
        .matchMedia("(min-width: 970px)")
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <>
        <div>
            <Helmet>
                <title>Franck Carvin</title>
                <meta name="description" content="Franck Carvin, Artiste, Peintre. Franck Carvin, artiste peintre passionné, révèle son talent depuis son jeune âge. Après une formation d'illustrateur, il affine sa maîtrise des détails et des émotions. Aujourd'hui, il crée des œuvres profondes en utilisant des techniques variées comme le fusain et l'acrylique. Inspiré par le romantisme, ses toiles aux textures et contrastes saisissants invitent à une expérience visuelle poétique et introspective, à la recherche de beauté et de vérité." />
                <link rel="canonical" href="/" />
            </Helmet>
            {matches && (
            <Gallery photos={photos} onClick={openLightbox} margin={16}/>
            )}
            {!matches && (
            <Gallery photos={photos} onClick={openLightbox} margin={4}/>
            )}
            {isOpen ? (
                <>
                <StyledLightbox
                    singleClickToZoom
                    isOpen={true}
                    onPrev={gotoPrevious}
                    onNext={gotoNext}
                    images={photos}
                    currentIndex={currentImage}
                    onClose={closeLightbox}
                    renderPrevButton={({ canPrev }) => (
                        <ArrowButton
                          position="left"
                          onClick={gotoPrevious}
                          disabled={!canPrev}
                        />
                    )}
                    renderNextButton={({ canNext }) => (
                    <ArrowButton position="right" onClick={gotoNext} disabled={!canNext} />
                    )}
                    renderFooter={() => <GalleryFooter images={photos} currentIndex={currentImage} onClose={closeLightbox}/>}
                />
                </>
                ) : null
            }
            <div className="footerDiv">
                <Footer></Footer>
            </div>
            
        </div>
        
        </>
    );
}

export default Home;

const StyledLightbox = styled(Lightbox)`
  background: ${({ theme }) =>
    Color(theme.accentColor)
      .alpha(0.90)
      .hsl()
      .string()};
  * ::selection {
    background: ${({ theme }) => theme.pageContentSelectionColor};
  }
  * ::-moz-selection {
    background: ${({ theme }) =>
      new Color(theme.pageContentSelectionColor).darken(0.27).hex()};
  }
`;