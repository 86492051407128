import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home/home';
import Header from './components/Header/Header';
import { Helmet } from "react-helmet";
import React, {useEffect } from 'react';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-SL3LWP880F"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    // <BrowserRouter basename="/tests/franckcarvin">
    <BrowserRouter>
      <Helmet>
        <title>Franck Carvin</title>
        <meta
          name="description"
          content="Franck Carvin, Artiste, Peintre. Franck Carvin, artiste peintre passionné, révèle son talent depuis son jeune âge. Après une formation d'illustrateur, il affine sa maîtrise des détails et des émotions. Aujourd'hui, il crée des œuvres profondes en utilisant des techniques variées comme le fusain et l'acrylique. Inspiré par le romantisme, ses toiles aux textures et contrastes saisissants invitent à une expérience visuelle poétique et introspective, à la recherche de beauté et de vérité."
        />
      </Helmet>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
