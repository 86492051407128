import React from "react";
import "./GalleryFooter.css";
import styled from "styled-components";
import ButtonControl from "../ButtonControl";
import Color from "color";
import { IoIosClose } from "react-icons/io";

const GalleryHeading = styled.h2`
  margin: 0 0 5px 0;
  font-weight: normal;
`;

const GallerySubheading = styled.h2`
  margin: 0;
  font-weight: normal;
  color: white;
`;

const PageIndicator = styled.span`
  white-space: nowrap;
  min-width: 60px;
  text-align: center;
  color: white;
  @media screen and (max-width: 970px) {
    font-size: 0.7rem;
    padding: 0;
    margin: 0;
    }
`;

const RightSideContainer = styled.div`
  width: 117px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 970px) {
    font-size: 0.7rem;
    padding: 0;
    margin: 0;
    width: 90px;
    }
`;

const CloseButton = styled(ButtonControl)`
  display: flex;
  width: 45px;
  border-left-width: 3px;
  border-left-color: ${({ theme }) => theme.headerNavFontColor};
  color: white;
  @media screen and (max-width: 970px) {
    width: 30px;
    margin: 0;
    padding: 0;
    }
`;

const LeftSideDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left-width: 3px;
  border-left-color: ${({ theme }) => theme.pageContentLinkHoverColor};
  border-left-style: solid;
  padding: 0px 0 0px 10px;
  margin: 0 0 3px 0;
  @media screen and (max-width: 970px) {
  font-size: 0.7rem;
  padding: 0;
  
  }
`;

const TopHeaderBar = styled.header`
  z-index: 10;
  cursor: auto;
  display: flex;
  justify-content: space-between;
  padding: 10px 2px 10px 20px;
  color: ${({ theme }) => theme.headerNavFontColor};
  background-color: ${({ theme }) =>
    Color(theme.pageBackgroundColor)
      .alpha(0.5)
      .hsl()
      .string()};
    height: 20px;
    @media screen and (max-width: 970px) {
      padding: 10px 0px 10px 10px;
      }
`;

const Footer = ({ galleryTitle, images, currentIndex, onClose }) => {
    return (
        <TopHeaderBar>
    <LeftSideDescriptionContainer>
      <GalleryHeading>{galleryTitle}</GalleryHeading>
      <GallerySubheading>{images[currentIndex].caption}</GallerySubheading>
    </LeftSideDescriptionContainer>

    <RightSideContainer>
      <PageIndicator>
        {currentIndex + 1} / {images.length}
      </PageIndicator>
      <CloseButton onClick={onClose} type="button">
        <IoIosClose size={60} />
      </CloseButton>
    </RightSideContainer>
  </TopHeaderBar>
    )   
};

export default Footer;
